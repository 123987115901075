<template>
    <section id="envole-tertiaire" class="page-section section-saint-ouen-7 saintOuen-7" :class="{'increment': increment}">
        <div class="envolTer__grid">
            <div class="sct-left">
                <h2 class="title-h2 desk-title-h2 anim-txt-focus">{{$t('titileTertiaire')}}</h2>
                <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-Tertiaire.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-Tertiaire-retina.jpg 2x"' alt=""></figure>
            </div>
      
            <div class="sct-right anim-slide-right sctIsDisplay">
                <div class="sct-right-box">
                    <div class="sct-right-ctn">
                        <h2 class="title-h2 mob-title-h2">{{$t('dataKeyChiffreTertiaire')['0']}}</h2>
                        <div class="key-number-item mb-30">
                            <p class="key-number"><span class="number-to-animate">
                            <number
                                animationPaused
                                ref="number1"
                                :from="100"
                                :to="1400000"
                                :format="theFormat"
                                :duration="3"
                                easing="Power1.easeOut"
                            />
                            </span> m<span class="m2">2</span></p>
                            <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['1']}}</p>
                        </div>
                        <div class="key-number-item mb-30">
                            <p class="key-number"><span class="number-to-animate">
                            <number
                                animationPaused
                                ref="number2"
                                :from="100"
                                :to="95000"
                                :format="theFormat"
                                :duration="3"
                                easing="Power1.easeOut"
                            /></span></p>
                            <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['2']}}</p>
                        </div>
                    </div>
                </div>

                <div class="sct-right-box">
                    <div class="sct-right-ctn">
                        <div class="mb-30">
                            <h4 class="title-h4">{{$t('dataKeyChiffreTertiaire')['3']}}</h4>
                            <p>{{$t('dataKeyChiffreTertiaire')['4']}}</p>
                        </div>
                        <div id="logoPartenaire" class="logo-partenaire">
                            <a :href="image.ref" v-for="image in images" :key="image.id" rel="noopener" target="_blank">
                                <figure><img  v-bind:src="image.src" alt=""/></figure>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   export default {
        name: 'SaintOuen7',
        props: {
            msg: String,
        },
        created () {
            window.addEventListener('wheel', this.isDisplay);
        },
        destroyed () {
            window.removeEventListener('wheel', this.isDisplay);
        },
        data:()=>({
            increment: false,
            showBrushK:false,
            images:[
                {id:1, src:"/images/logo/logo-allianz.svg", ref: "https://www.allianz.com"},
                {id:2, src:"/images/logo/logo-Alstom.svg", ref: "https://www.alstom.com"},
                {id:3, src:"/images/logo/logo-amazon.svg", ref: "https://www.amazon.com"},
                {id:4, src:"/images/logo/logo-bic.svg", ref: "https://www.bic.com"},
                {id:5, src:"/images/logo/logo-oreal.svg", ref: "https://www.oreal.com"},
                {id:6, src:"/images/logo/logo-monoprix.svg", ref: "https://www.monoprix.com"},
                {id:7, src:"/images/logo/logo-ocp.svg", ref: "https://www.ocp.com"},
                {id:8, src:"/images/logo/logo-samsung.svg", ref: "https://www.samsung.com"}
            ]
        }),
        methods:{
            
            theFormat(number) {
                return this.numberWithSpaces(number.toFixed(0));
            },

            numberWithSpaces(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            },

            isDisplay(){
                let saintOuen7 = document.querySelector('#envole-tertiaire');
                let parentBox = saintOuen7.parentElement
                let activeBloc = parentBox.parentElement
                if(activeBloc.classList.contains('active')){
                    setTimeout(() => {
                        this.$refs.number1.play()
                        this.$refs.number2.play()
                    }, 200);
                }
            },
            playAnimation(){
                var inter = setInterval(() => {
                    if(window.location.hash == "#envole-tertiaire"){
                        setTimeout(() => {
                            this.$refs.number1.play()
                            this.$refs.number2.play()
                        }, 500);
                    }
                }, 1000);
                window.addEventListener("wheel", function(){
                    clearInterval(inter)
                })
            }
        },
        mounted(){
            //****************************** */
            // Increase number Mobile
            //****************************** */
            var circleBlocParent = document.querySelectorAll(".sctIsDisplay");

            const observer = new IntersectionObserver(([entry]) => {
                if( screen.width <= 1200 ) {
                    if (entry && entry.isIntersecting) {
                        setTimeout(() => {
                            this.$refs.number1.play()
                            this.$refs.number2.play()
                        }, 200);         
                    }
                }
            });
            for(let item of circleBlocParent){
                observer.observe(item);
            }
        }
    };
</script>